import React from 'react'

const SvgDataSyncApp3 = props => (
  <svg id='dataSync_app3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 160 114' xmlSpace='preserve' {...props}>
    <style>
      {
        '.dataSync_app3_svg__st5{fill:#252a3a}.dataSync_app3_svg__st6{fill:none;stroke:#fd5c1f;stroke-width:2;stroke-miterlimit:10}'
      }
    </style>
    <linearGradient
      id='dataSync_app3_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={16.553}
      y1={114.513}
      x2={143.452}
      y2={-12.387}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      d='M153 101.06H7c-3.31 0-6-2.69-6-6v-88c0-3.31 2.69-6 6-6h146c3.31 0 6 2.69 6 6v88c0 3.32-2.68 6-6 6z'
      fill='none'
      stroke='url(#dataSync_app3_svg__SVGID_1_)'
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <linearGradient
      id='dataSync_app3_svg__SVGID_2_'
      gradientUnits='userSpaceOnUse'
      x1={55.003}
      y1={113.063}
      x2={105.003}
      y2={113.063}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.3} />
      <stop offset={0.995} stopColor='#252a3a' />
    </linearGradient>
    <path
      fill='none'
      stroke='url(#dataSync_app3_svg__SVGID_2_)'
      strokeWidth={2}
      strokeMiterlimit={10}
      d='M55 113.06h50'
    />
    <linearGradient
      id='dataSync_app3_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={73.187}
      y1={115.206}
      x2={86.818}
      y2={101.575}
    >
      <stop offset={0} stopColor='#252a3a' stopOpacity={0.2} />
      <stop offset={0.995} stopColor='#252a3a' stopOpacity={0.8} />
    </linearGradient>
    <path fill='url(#dataSync_app3_svg__SVGID_3_)' d='M89.96 112.06H70.04l2.66-10h14.61z' />
    <path
      d='M81.39 42.14c-1.71 0-3.42.33-4.92.7l1.11.8c-1.5.28-3.04.2-3.88.01v3.28c1.14.3 4.9.08 6.55-.59l-.52-.62c.61-.06 1.02-.07 1.65-.07 1.76 0 3.42.24 4.92.68v-3.48c-1.41-.36-3.2-.71-4.91-.71z'
      fill='#fd5c1f'
    />
    <path
      className='dataSync_app3_svg__st5'
      d='M86.78 55.03c-1.16.12-1.98.31-2.8.55v.98c-1.26.28-3.31.57-4.96.46-1.7-.11-3.9-.72-4.85-1.08v-3.53c1.35.72 3.9 1.2 6.01 1.2 1.17 0 2.61-.15 3.46-.33l-.17-.86c1.08-.37 1.75-.68 3.32-.84v3.45zM84.57 47c-1.23 0-3.02.28-3.53.42l.59.94c-1.32.33-2.64.33-3.61.33-2.27 0-4.2-.48-6.2-1.24v3.56c1.54.52 3.52.93 5.39.93 1.87 0 3.93-.25 5.69-.86l-.16-.43c.61-.08 1.13-.12 1.8-.12 1.26 0 2.32.04 3.64.36v-3.57c-.73-.16-2.38-.32-3.61-.32z'
    />
    <g>
      <ellipse className='dataSync_app3_svg__st6' cx={80} cy={49.59} rx={15.47} ry={40} />
      <ellipse
        transform='rotate(-27.977 80.003 49.593)'
        className='dataSync_app3_svg__st6'
        cx={80}
        cy={49.59}
        rx={42.03}
        ry={14.73}
      />
      <ellipse
        transform='rotate(-62.023 80.007 49.594)'
        className='dataSync_app3_svg__st6'
        cx={80}
        cy={49.59}
        rx={14.73}
        ry={42.03}
      />
    </g>
  </svg>
)

export default SvgDataSyncApp3
