import styled, { css } from 'styled-components'
import { Wrapper, Flex, Divider } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'

export const SectionDesc = styled.p`
  ${({ theme: { color, typography } }) => css`
    color: ${color.text};
    line-height: 24px;
    font-size: ${typography.textSmall};
    padding-top: ${p => p.top + 'px'};
    padding-bottom: ${p => p.bottom + 'px'};
    ${Media.phone`
      line-height: .48rem;
      padding-top: ${p => p.top / 100 + 'rem'};
      padding-bottom: ${p => p.bottom / 100 + 'rem'};
    `}
  `}
`

export const FeatureSection = styled(Wrapper)`
  padding: 40px 0 68px 0;
  ${Media.phone`
    padding-top: .92rem;
    padding-bottom: 0.92rem;
  `}
`

export const Section = styled(Wrapper)`
  padding: 87px 0 21px 0;
  img {
    display: block;
    margin: 0 auto;
  }
  ${Media.phone`
    padding-top: .83rem;
    padding-bottom: 0rem;
  `}
`
export const ApplicationSection = styled(Wrapper)`
  padding: 80px 0 80px 0;
  ${Media.phone`
    padding-top: .4rem;
    padding-bottom: 1.26rem;
  `}
`
export const ApplicationContainer = styled(Flex)`
  margin-top: 41px;
  justify-content: space-between;
  ${Media.phone`
    flex-wrap: wrap;
  `}
`
export const ApplicationItem = styled.div`
  width: 276px;
  height: 340px;
  box-shadow: 0px 0px 12px 0px rgba(228, 232, 234, 1);
  svg {
    width: 160px;
    height: 114px;
    display: block;
    margin: 60px auto 67px;
  }
  h4 {
    line-height: 24px;
    text-align: center;
  }

  ${p => p.theme.Hack.desktopEn`
    svg {
      margin-bottom: 45px;
    }
    h4 {
      padding: 0 10px;
      line-height: 28px;
    }
  `}

  ${Media.phone`
    width: 3.3rem;
    height: initial;
    min-height: 4rem;
    padding-bottom: .3rem;
    margin-bottom: .3rem;
    svg{
      width: 1.9rem;
      height: 1.35rem;
      margin: .72rem auto .74rem;
    }
    h4 {
      font-size: .3rem;
    }
  `}
`
export const SolutionDivider = styled(Divider)`
  ${({ theme: { color } }) => css`
    background: ${color.line};
  `}
`

export const ArchSection = styled.div`
  ${({ theme: { color } }) => css`
    padding: 80px 0 80px 0;
    background-color: ${color.panel};
    svg {
      display: block;
      width: 80%;
      min-height: 300px;
      margin: 46px auto 0;
    }
    ${Media.phone`
      padding-top: .46rem;
      padding-bottom: 0.75rem;
      background-color: white;


      svg {
        width: 7rem;
        margin-top: .62rem;
        min-height: 3rem;
      }
    `}
  `}
`

export const MultiApplicationItem = styled.div`
  ${({ theme: { typography, Hack } }) => css`
    box-shadow: 0px 0px 12px 0px rgba(228, 232, 234, 1);
    > div {
      position: relative;
      width: 276px;
      height: 160px;
    }
    > h4 {
      line-height: 76px;
      font-size: ${typography.text};
      text-align: center;
    }

    ${Hack.desktopEn`
      font-size: ${typography.h4};
    `}

    ${Media.phone`
      margin-bottom: .43rem;
      >div {
        width: 3.3rem;
        height: 1.9rem;
      }
      > h4 {
        line-height: .9rem;
        font-size: .3rem;
      }
    `}
  `}
`
export const FeatureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    height: 280px;
  }
`
export const MainItem = styled.div`
  ${({ theme: { color } }) => css`
    width: 500px;
    background: ${p => (p.active ? p.theme.color.panel : '#fff')};
    position: relative;
    min-height: 50px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    h2 {
      font-size: 14px;
      line-height: 24px;
      padding: 0 10px;
    }
    p {
      min-height: 40px;
      font-size: 14px;
      color: ${color.text};
      line-height: 24px;
    }
  `}
`

export const MobileMainItem = styled.div`
  ${({ theme: { color } }) => css`
    padding: 0.4rem 0.3rem 0.3rem;
    margin-bottom: 0.2rem;
    min-height: 6rem;
    background: white;
    box-shadow: 0px 0px 6px 0px rgba(226, 231, 236, 1);
    border-radius: 10px;
    img {
      display: block;
      margin: 0 auto 0.5rem;
      width: 100%;
      height: 4rem;
    }
    h2 {
      font-size: 0.3rem;
    }
  `}
`
