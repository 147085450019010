import React from 'react'

const SvgDataSyncFeature3 = props => (
  <svg id='dataSync_feature3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.dataSync_feature3_svg__st0{fill:none;stroke:#334966;stroke-width:2}'}</style>
    <g id='dataSync_feature3_svg__\u591A\u5B58\u50A8\u7C7B\u578B-48x48'>
      <circle id='dataSync_feature3_svg__Oval-7_2_' className='dataSync_feature3_svg__st0' cx={25} cy={25} r={23} />
      <ellipse
        id='dataSync_feature3_svg__Oval-13'
        className='dataSync_feature3_svg__st0'
        cx={25}
        cy={31}
        rx={11}
        ry={6}
      />
      <ellipse id='dataSync_feature3_svg__Oval-13-Copy' cx={25} cy={25} rx={12} ry={7} fill='#fd5c1f' />
      <ellipse
        id='dataSync_feature3_svg__Oval-13-Copy-2'
        className='dataSync_feature3_svg__st0'
        cx={25}
        cy={19}
        rx={11}
        ry={6}
      />
    </g>
  </svg>
)

export default SvgDataSyncFeature3
